@import "./tootltip";
@import "scrollbar";

button.no-pointer {
  cursor: auto !important;
}

.tooltip-normal {
  opacity: 1;
  cursor: pointer;
}

.turbamedia-fab {
  color: white;
  background-color: var(--bs-primary) !important;
  border-radius: 50% !important;
  width: 50px !important;
  height: 50px !important;
  opacity: 70%;
}

.list-leads-action {
  .opacity-10 {
    opacity: 1;
  }

  .opacity-8 {
    opacity: 0.8;
  }

  .opacity-6 {
    opacity: 0.6;
  }
}

.hidden-input {
  width: 0px;
  opacity: 0;
}

.hide-on-mobile {
  display: none;
}

@media (min-width: 768px) {
  .hide-on-mobile {
    display: inline;
  }
}

.three-lines-with-scroll {
  margin-left: 75px;
  margin-top: 15px;
  height: 4.5em;
  overflow-y: auto;
  padding-right: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  line-height: 1.5em;
  -webkit-overflow-scrolling: touch;
}